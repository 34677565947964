// For xml date+time;
//Url
function addTrailingDirSlash(url) {
    if (url == "") { return "" }
    url = url.replace(/\/\/+/gi, "/").toLowerCase();
    if (url.charAt(0) == '/') { url = url.substring(1) }
    if (url == "" || url == "/") { return "" }
    var urlParts = url.split("/");
    if (urlParts[urlParts.length - 1].indexOf(".") !== -1) { return url }
    return (url + "/").replace(/\/\/+/gi, "/");
}



// Disable button
function disableButtonOnClick(target, text, waitText, seconds) {
    Page_ClientValidate();
    if (!Page_IsValid) return false;
    return disableButton(target, text, waitText, seconds);
}

function disableButton(target, text, waitText, seconds) {
    if (target.countdownInterval != undefined) return false
    target.countdownValue = seconds;
    target.text = text;
    target.waitText = waitText;
    disableButtonCheck(target);
    target.countdownInterval = setInterval(() => { disableButtonCheck(target); }, 1000);
    return true;
}

function disableButtonCheck(target) {
    if (target.countdownValue <= 0) {
        target.disabled = false;
        target.value = target.text;
        target.classList.remove("aspNetDisabled");
        clearInterval(target.countdownInterval);
        target.countdownInterval = null;
    }
    else {
        //target.disabled = true;
        target.value = target.text + " " + target.waitText + " " + target.countdownValue;
        target.classList.add("aspNetDisabled");
    }
    target.countdownValue -= 1;
}
